<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="45%" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <div class="speech-bubble">
          <button
            class="bubble"
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <div class="text-service">O que posso fazer em cada serviço?</div>
            <div class="text-information">Clique <b>aqui</b> para saber</div>
          </button>
          <div class="pointer"></div>
          <div>
            <div class="bubble blurred"></div>
            <div class="pointer blurred"></div>
          </div>
        </div>
      </template>

      <v-card>
        <div class="scroll" max-height="75px">
          <div
            class="container-service"
            v-for="servico in servicos"
            :key="servico.id"
          >
            <v-card-text class="text-h6">
              <b>Em {{ servico.name }} você pode: </b>
            </v-card-text>
            <div class="description-title">
              <div
                class="description-list"
                v-for="description in servico.description"
                :key="description.id"
              >
                - {{ description }}
              </div>
            </div>
          </div>
        </div>

        <v-divider />

        <div>
          <button class="button" @click="dialog = false">Voltar</button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DescriptionCard",
  props: ["servicos"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped>
.text-service {
  font-weight: bold;
  font-size: 20px;
}

.text-information {
  font-weight: lighter;
  font-size: 18px;
}

.scroll {
  overflow-y: scroll;
  height: 600px;
  padding-block: 25px;
  padding-left: 30px;
}
.description-list {
  margin-left: 4%;
  font-size: 20px;
}
.container-service {
  margin-bottom: 4%;
  margin-left: 4%;
}

.description-title {
  margin-top: -15px;
}

.button {
  margin: 0 auto;
  color: #ff5252;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble {
  height: 160px;
  width: 55px;
  min-width: 220px;
  background: #ffffff;
  display: block;
  margin: 0 auto;
  border-radius: 70px;
  margin-top: 50px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  box-shadow: 10px 10px 5px #c4d7ed;
}

.pointer {
  height: 40px;
  width: 40px;
  background: #ffffff;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 12px 0;
  margin-top: -22px;
  position: right;
  left: calc(0.5vw - 50px);
}

.blurred {
  background: gray;
  filter: blur(20px);
  position: relative;
  top: -315px;
  /* transform: scale(1.05); */
  z-index: -1;
}
.speech-bubble {
  height: 550px;
  width: 200px;
  position: absolute;
  bottom: 170px;
  right: 55px;
}
</style>
